<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(this.action == 'insert')">{{ $t("ROLE.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(this.action == 'update')">{{ $t("ROLE.EDIT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="6">
              <MDBRow>
                <MDBCol md="12" class="mb-2">
                  <div>
                    <label class="form-label text-dark">{{ $t('COMMON.NAME') }}<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.NAME')" v-model="Form.name.value">
                    <span class="text-danger">{{ nameError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md="6">
              <div>
                <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.PERMISSIONSETTING') }}</label>
                <TreeSelect2 :items="permissions" v-model:selected="Form.permissions.value">
                  <template v-slot:name="{ item }">
                    {{ $t(`HEADER.${item.name}`) }}
                  </template>
                </TreeSelect2>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
        <MDBBtn v-if="(this.action == 'insert')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn v-else-if="(this.action == 'update')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.UPDATE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="CloseModel()">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import TreeSelect2 from '@/components/TreeSelect2';
import { useForm, useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

export default {
  name: "RoleForm",
  components: {
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    MDBCol,
    TreeSelect2
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    uuid: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
  },
  inject: ["permissions"],
  setup(props, { emit }) {

    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val),
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const SubmitLoading = ref(false);
    const i18n = useI18n();

    const basicSchema = {
      name: yup.string().required().label(i18n.t("COMMON.NAME")),
      permissions: yup.array().label(i18n.t("COMMON.PERMISSIONSETTING"))
    };

    let ExtendSchema = {};
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        name: "",
        permissions: [],
      }
    });

    const { value: name, errorMessage: nameError } = useField('name');
    const { value: permissions, errorMessage: permissionsError } = useField('permissions');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Role", values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            SubmitLoading.value = false;
            GetTable();
          }
        });
        return false;
      });
    }else {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Role", props.uuid, values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            SubmitLoading.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
        return false;
      });
    }

    return {
      Model,
      SubmitLoading,
      FormUUid,
      Form: {
        name,
        permissions
      },
      Submit,
      nameError,
      permissionsError,
      resetForm,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Role", this.FormUUid).then(response => {
            if (response.status == 200) {
              this.Form.permissions.value = [];
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "permissions":
                      response.data.data[k].map(item => {
                        this.Form[k].value.push(item.method);
                      });
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              resolve(response);
            }
          });
        })
      ]).then(() => {
        this.Model = true;
      });
    },
    CloseModel() {
      this.resetForm();
      this.UserUUid = "";
      this.Model = false;
    }
  }
};
</script>