<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol class="mb-2 text-end">
            <!-- <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn> -->
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("ROLE.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:login_at="{ item }">
            {{ (item.authentications.length > 0)?Common.TimeFormat(item.authentications[0].login_at):"" }}
          </template>
          <template v-slot:login_count="{ item }">
            {{ item.authentications.length }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('ROLE.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('ROLE.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<RoleForm action="insert" v-model:model="add_dialog"></RoleForm>
	<RoleForm action="update" v-model:model="edit_dialog" v-model:uuid="EditUUid"></RoleForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import RoleForm from '@/components/Form/RoleForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive, provide } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "RoleManage",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		RoleForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    PageLoading
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const desserts = ref([]);
    const total = ref(0);
    const Common = CommonService;
    const EditUUid = ref("");
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const PermissionItems = reactive([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",width:"50px",class: "text-center"
      },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,width:"50px",class: "text-center"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,width:"50px",class: "text-center"}
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Role",
          {
            params: CommonService.SortHandleObject(options)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetPermission = function() {
      return new Promise(resolve => {
        ApiService.get("/Admin/Permission").then(response => {
          if (response.status == 200) {
            PermissionItems.push(...response.data.data);
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Role/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    provide("GetTable",GetTable);
    provide("permissions",PermissionItems);

		return {
      add_dialog,
      edit_dialog,
      loading,
      PageLoading,
      options,
      headers,
      desserts,
      total,
      Common,
      EditUUid,
      PermissionItems,
      GetTable,
      GetPermission,
      DeleteAction
    };
  },
  mounted() {
    Promise.all([
      this.GetTable(),
      this.GetPermission()
    ])
    .then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  }
};
</script>